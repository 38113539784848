import './banners.css'
import { Carousel, } from 'react-bootstrap'
import Banner1 from '../../assets/imgs/banner1.jpg'
import Banner2 from '../../assets/imgs/banner2.jpg'

function Banners() {
    return (
        <Carousel fade variant="light">
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={Banner1}
                    alt="First slide"
                />
                <Carousel.Caption>
                    <h3 className='title-banner'>Montevidéu</h3>
                    {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={Banner2}
                    alt="Second slide"
                />

                <Carousel.Caption>
                    <h3 className='title-banner'>LAS VEGAS</h3>
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    )
}

export default Banners