import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './footer.css'
import LogoLight from '../../assets/imgs/logo-light.png'
import { Link } from 'react-router-dom'

function Footer(props) {

    return(
        <>
            <Container fluid className="footer-container">
                <Container>
                    <Row className="footer-top">
                        <Col xs={12} sm={3} className="text-center pb-3">
                            <img className="w-100" src={LogoLight}/>
                        </Col>
                        <Col xs={12} sm={6}>
                            A <strong>Mirum</strong> é uma agência de viagens especializada em clientes de resorts e cassinos ao redor do mundo, atuando como um concierge de atendimento e na negociação de pacotes de benefícios e fidelização.
                        </Col>
                        <Col xs={12} sm={3} className='d-none d-md-block'>
                            <ul className="menu-footer">
                                <li><Link className="link-footer" to="/servicos">Serviços</Link></li>
                                <li><Link className="link-footer" to="/corporativo">Corporativo</Link></li>
                                <li><Link className="link-footer" to="/cassinos">Cassinos</Link></li>
                                <li><Link className="link-footer" to="/sobre">Sobre</Link></li>
                                <li><Link className="link-footer" to="/contato">Contato</Link></li>
                            </ul>
                        </Col>
                    </Row>
                    <Row className="footer-bottom">
                        <Col className="text-center">
                            Atendimento de segunda à sexta, das 9h30 às 19h, no telefone/whatsapp: +55 11 94044-0078
                            <br/>
                            Praça Cel Olímpio Gonçalves dos Reis, 278 - Socorro - SP, CEP 13960000
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    )
}

export default Footer