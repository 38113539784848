import Header from '../../components/Header'
import Title from '../../components/Title'
import Footer from '../../components/Footer'
import { Container } from 'react-bootstrap'
import Breadcrumbs from '../../components/Breadcrumbs'

function Corporate() {
    return (
        <>
            <Header />
            <Breadcrumbs route="/corporativo" label="Corporativo"/>
            <Title primary="Corporativo" center />
            <Container>
                <p>
                A Mirum atende grandes, médias e pequenas empresas com os melhores serviços de viagem, para tornar a gestão viagens da sua empresa mais eficiente, dinâmica e econômica.
                </p>
                <p>
                O serviço de agência de viagens pode ajudar a empresa a gastar menos tempo e dinheiro com planejamento e gestão de voos e hospedagem.
                </p>
            </Container>

            <Footer />
        </>

    )
}

export default Corporate