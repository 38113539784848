import Header from '../../components/Header'
import Title from '../../components/Title'
import Footer from '../../components/Footer'
import { Container } from 'react-bootstrap'
import Breadcrumbs from '../../components/Breadcrumbs'

function About() {
    return (
        <>
        <Header/>
        <Breadcrumbs route="/sobre" label="Sobre"/>
        <Title primary="Sobre" center/>
        <Container>
                <p>
                A Mirum é uma agência de viagens especializada em clientes de resorts e cassinos ao redor do mundo, atuando como um concierge de atendimento e na negociação de pacotes de benefícios e fidelização.
                </p>
            </Container>
        <Footer/>
        </>
    )
}

export default About