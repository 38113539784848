import React from 'react'
import './header.css'
import Menu from '../Menu'
import { Col, Container, Row } from 'react-bootstrap'

import Logo from '../../assets/imgs/logo.png'

function Header(props) {

    return(
        <>
        <Container>
            <Row className="header-top">
                <Col className="header-top-left ">
                    Concierge VIP para os<br className="d-none d-md-block"/>melhores resorts do mundo.
                </Col>
                <Col className="header-top-center d-none d-md-block text-center">
                    <img width="200" className="logo" src={Logo}/>
                </Col>
                <Col className="header-top-right d-none d-md-block">
                    <strong>Viaje de graça</strong> para resorts e<br/>cassinos no mundo todo.
                </Col>
            </Row>
        </Container>
        <Container fluid className="container-menu">
            <Menu/>
        </Container>
        
        </>
    )
}

export default Header