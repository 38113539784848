import React from 'react'
import './title.css'
import { Container, Row } from 'react-bootstrap'

function Title(props) {

    const handleTitle = () => {

        if (props.primary) return <h1 className="title-primary">{props.primary}</h1>
        if (props.secodary) return <h3 className="title-primary">{props.secodary}</h3>
        
    }

    return (
        <>
        <Container>
            <Row className={props.center ? 'text-center': ''}>
                {handleTitle()}
            </Row>
        </Container>
            
        </>
    )
}

export default Title