import Header from '../../components/Header'
import Title from '../../components/Title'
import Footer from '../../components/Footer'
import CardList from '../../components/CardList'
import Breadcrumbs from '../../components/Breadcrumbs'
import CasinoList from '../../data/Casinos/CasinoList'

function Casinos() {
    return(
        <>
        <Header/>
        <Breadcrumbs route="/cassinos" label="Cassinos"/>
        <Title primary="Cassinos" center/>
        <CardList horizontal list={CasinoList}/>
        <Footer/>
        </>
    )
}

export default Casinos