import Header from '../../components/Header'
import Title from '../../components/Title'
import Footer from '../../components/Footer'
import ContactForm from '../../components/ContactForm'
import Breadcrumbs from '../../components/Breadcrumbs'

function Contact() {
    return(
        <>
        <Header/>
        <Breadcrumbs route="/contato" label="Contato"/>
        <Title primary="Contato" center/>
        <ContactForm/>
        <Footer/>
        </>
    )
}

export default Contact