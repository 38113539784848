import BELLAGIO from '../../assets/imgs/casinos/BELLAGIO.jpg'
import ARIA from '../../assets/imgs/casinos/ARIA.jpg'
import NEW_YORK from '../../assets/imgs/casinos/NEW_YORK.jpg'
import LUXOR from '../../assets/imgs/casinos/LUXOR.jpg'
import HARD_ROCK from '../../assets/imgs/casinos/HARD_ROCK.jpg'
import CASINO_MADERO from '../../assets/imgs/casinos/CASINO_MADERO.jpg'

export default [
    {
        img: BELLAGIO,
        title: 'BELLAGIO',
        description: 'Las Vegas, Estados Unidos'
    },
    {
        img: ARIA,
        title: 'ARIA',
        description: 'Las Vegas, Estados Unidos'
    },
    {
        img: NEW_YORK,
        title: 'NEW YORK NEW YORK',
        description: 'Las Vegas, Estados Unidos'
    },
    {
        img: LUXOR,
        title: 'LUXOR',
        description: 'Las Vegas, Estados Unidos'
    },
    {
        img: HARD_ROCK,
        title: 'HARD ROCK PUNTA CANA',
        description: 'Punta Cana, República Dominicana'
    },
    {
        img: CASINO_MADERO,
        title: 'CASINO MADERO',
        description: 'Buenos Aires, Argentina'
    }
]