import React from 'react'
import { Container, Row } from 'react-bootstrap'
import CartItem from '../CardItem'

function CardList(props) {

    const list = props.list

    return (
        <>
        <Container>
            <Row>
            {
                list.map((item) => {
                    return <CartItem 
                        horizontal={props.horizontal ? true : false}
                        img={item.img} 
                        title={item.title}
                        description={item.description} />
                })
            }
            </Row>
        </Container>
            
        </>
    )
}

export default CardList