import React from 'react'
import './menu.css'
import { Navbar, Container, Offcanvas, Nav} from 'react-bootstrap'
import Logo from '../../assets/imgs/logo.png'
import { Link } from 'react-router-dom'

function Menu(props) {

    const expand = 'md'

    return (
        <>
            <Navbar key={expand} bg="none" expand={expand} className="mb-2">
                <Container fluid>
                <Navbar.Brand href="#">
                    <img width="100" className="logo d-md-none d-block" src={Logo}/>
                </Navbar.Brand>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-${expand}`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                        placement="end"
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                Menu
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="justify-content-center flex-grow-1 pe-3">  
                                <Link className={`item-menu py-2 py-${expand}-0`} to="/home">Home</Link>
                                <Link className={`item-menu py-2 py-${expand}-0`} to="/servicos">Serviços</Link>
                                <Link className={`item-menu py-2 py-${expand}-0`} to="/corporativo">Corporativo</Link>
                                <Link className={`item-menu py-2 py-${expand}-0`} to="/cassinos">Cassinos</Link>
                                <Link className={`item-menu py-2 py-${expand}-0`} to="/sobre">Sobre</Link>
                                <Link className={`item-menu py-2 py-${expand}-0`} to="/contato">Contato</Link>
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        </>
    )
}

export default Menu