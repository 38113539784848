import Header from '../../components/Header'
import Banners from '../../components/Banners'
import Title from '../../components/Title'
import CardList from '../../components/CardList'
import Footer from '../../components/Footer'
import MainServicesList from '../../data/Services/MainServicesList'

function Home() {
    return(
        <>
        <Header/>
        <Banners/>
        <Title primary="Serviços" center/>
        <CardList list={MainServicesList}/>
        <Footer/>
        </>
        
    )
}

export default Home