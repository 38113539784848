import React, { useState, useRef } from 'react'
import './contactForm.css'
import { Form, Container, Row, Col, Button } from 'react-bootstrap'
import { FaPhoneAlt, FaWhatsapp } from "react-icons/fa";
import Title from '../Title'
import InputMask from 'react-input-mask';
import emailjs from '@emailjs/browser';
import { EMAIL } from '../../environments'
import { useNavigate } from "react-router-dom";

function ContactForm(props) {

    let navigate = useNavigate();
    const form = useRef();
    const [noValidateName, setNoValidateName] = useState(false)
    const [noValidateEmail, setNoValidateEmail] = useState(false)
    const [noValidateMessage, setNoValidateMessage] = useState(false)
    const [sendSuccess, setSendSuccess] = useState(false)

    const handleSendEmail = (e) => {
        e.preventDefault();

        form.current.name.value === "" ? setNoValidateName(true) : setNoValidateName(false)
        form.current.email.value === "" ? setNoValidateEmail(true) : setNoValidateEmail(false)
        form.current.message.value === "" ? setNoValidateMessage(true) : setNoValidateMessage(false)

        if (form.current.checkValidity() === false) {
            e.stopPropagation();
            return
        }

        emailjs.sendForm(
            EMAIL.YOUR_SERVICE_ID,
            EMAIL.YOUR_TEMPLATE_ID,
            form.current,
            EMAIL.YOUR_PUBLIC_KEY)
            .then((result) => {
                setSendSuccess(true)
                setTimeout(() => {
                    navigate("/home");
                }, 2000)
            }, (error) => {
                console.log(error.text);
            });
    };


    return (
        <>
            <Container className="mb-5">
                <Row>
                    <Col xs={12} sm={6}>
                        <Title secodary="Atendimento" center />
                        <div className="text-center">
                            Atendimento de segunda à sexta, <br />das 9h30 às 19h,
                            <br />
                            <FaPhoneAlt size="18" /> <FaWhatsapp size="25" className="mx-2" /> +55 11 94044-0078
                        </div>
                    </Col>
                    <Col xs={12} sm={6}>

                        <Form noValidate ref={form} onSubmit={handleSendEmail}>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>*Nome:</Form.Label>
                                <Form.Control isInvalid={noValidateName} name="name" type="text" required placeholder="Digite seu nome" />
                                <Form.Control.Feedback type="invalid">
                                    Campo Obrigatório
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="email">
                                <Form.Label>*Email:</Form.Label>
                                <Form.Control isInvalid={noValidateEmail} name="email" required type="email" placeholder="Digite seu e-mail" />
                                <Form.Control.Feedback type="invalid">
                                    Campo Obrigatório
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="tel">
                                <Form.Label>Telefone:</Form.Label>
                                <InputMask className="form-control" name="tel" mask="(19) 99999 9999" maskChar=" " />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="message">
                                <Form.Label>*Mensagem:</Form.Label>
                                <Form.Control isInvalid={noValidateMessage} name="message" required as="textarea" rows={3} />
                                <Form.Control.Feedback type="invalid">
                                    Campo Obrigatório
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Row>
                                <Col xs={12} sm={6}>
                                    <Button className="btn-submit" type="submit" variant="dark">Enviar</Button>
                                    {sendSuccess
                                        ? <div className="success-contact">Enviado com sucesso!</div>
                                        : ''}
                                </Col>
                            </Row>
                        </Form>

                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default ContactForm