import Header from '../../components/Header'
import Title from '../../components/Title'
import Footer from '../../components/Footer'
import CardList from '../../components/CardList'
import Breadcrumbs from '../../components/Breadcrumbs'
import ServicesList from '../../data/Services/ServicesList'

function Services() {
    return(
        <>
        <Header/>
        <Breadcrumbs route="/servicos" label="Serviços"/>
        <Title primary="Serviços" center/>
        <CardList list={ServicesList}/>
        <Footer/>
        </>
    )
}

export default Services