import ServicesImg from '../../assets/imgs/services-mock.jpg'

export default [
    {
        img: ServicesImg,
        title: 'CÂMBIO FACILITADO',
        description: 'Pague e/ou receba no Brasil com agilidade e ajuda tributária.'
    },
    {
        img: ServicesImg,
        title: 'SPA & PISCINA',
        description: 'Aproveite para relaxar em piscinas e serviços de spa incríveis.'
    },
    {
        img: ServicesImg,
        title: 'GASTRONOMIA',
        description: 'Aproveite experiências gastrônomicas únicas nos melhores restaurantes.'
    },
    {
        img: ServicesImg,
        title: 'CÂMBIO FACILITADO',
        description: 'Pague e/ou receba no Brasil com agilidade e ajuda tributária.'
    },
    {
        img: ServicesImg,
        title: 'SPA & PISCINA',
        description: 'Aproveite para relaxar em piscinas e serviços de spa incríveis.'
    },
    {
        img: ServicesImg,
        title: 'GASTRONOMIA',
        description: 'Aproveite experiências gastrônomicas únicas nos melhores restaurantes.'
    }
]