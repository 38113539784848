import React from 'react'
import './breadcrumbs.css'
import { Link } from 'react-router-dom'
import { Container, Col, Card, Button, Row } from 'react-bootstrap'

function Breadcrumbs(props) {

    return (
        <>
            <Container className="back-breadcrumbs" fluid>
                <Container>
                    <Row >
                        <Col className='links-breadcrumbs'>
                            <Link to="/home">Home</Link> / <Link to={props.route}>{props.label}</Link>
                        </Col>
                    </Row>

                </Container>
            </Container>
        </>
    )
}

export default Breadcrumbs