import {
    BrowserRouter,
    Routes,
    Route,
  } from "react-router-dom";

import Home from './pages/home'
import Services from './pages/services'
import Contact from './pages/contact'
import Corporate from './pages/corporate'
import Casinos from './pages/casinos'
import About from './pages/about'

function Routing() {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="home" element={<Home />} />
                <Route path="servicos" element={<Services />} />
                <Route path="contato" element={<Contact />} />
                <Route path="corporativo" element={<Corporate />} />
                <Route path="cassinos" element={<Casinos />} />
                <Route path="sobre" element={<About />} />
            </Routes>
        </BrowserRouter>
    )
}

export default Routing