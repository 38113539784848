import ServicesImg from '../../assets/imgs/services-mock.jpg'

export default [
    {
        img: ServicesImg,
        title: 'BRASIL A DENTRO',
        description: 'Pague e/ou receba no Brasil com agilidade e ajuda tributária.'
    },
    {
        img: ServicesImg,
        title: 'MUNDO A FORA',
        description: 'Aproveite para relaxar em piscinas e serviços de spa incríveis.'
    },
    {
        img: ServicesImg,
        title: 'PASSAPORTE - EMISSÃO E RENOVAÇÃO',
        description: 'Aproveite experiências gastrônomicas únicas nos melhores restaurantes.'
    },
    {
        img: ServicesImg,
        title: 'VISTO AMERICANO - EMISSÃO E RENOVAÇÃO',
        description: 'Pague e/ou receba no Brasil com agilidade e ajuda tributária.'
    },
    {
        img: ServicesImg,
        title: 'VISTOS - OUTROS VISTOS',
        description: 'Aproveite para relaxar em piscinas e serviços de spa incríveis.'
    }
]