import React from 'react'
import './cardItem.css'
import { Col, Card, Button, Row } from 'react-bootstrap'

function CardItem(props) {

    return (
        <>
            {props.horizontal
                ?
                <Col xs={12} sm={6}>
                    <Card className="card-item">
                        <Row>
                            <Col xs={6}>
                                <Card.Img variant="top" src={props.img} />
                            </Col>
                            <Col xs={6}>
                                <Card.Body>
                                    <Card.Title>{props.title}</Card.Title>
                                    <Card.Text>
                                        {props.description}
                                    </Card.Text>
                                    <Button variant="light">Veja Mais</Button>
                                </Card.Body>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                :
                <Col xs={12} sm={4}>
                    <Card className="card-item">
                        <Card.Img variant="top" src={props.img} />
                        <Card.Body>
                            <Card.Title>{props.title}</Card.Title>
                            <Card.Text>
                                {props.description}
                            </Card.Text>
                            <Button variant="light">Veja Mais</Button>
                        </Card.Body>
                    </Card>
                </Col>}
        </>
    )
}

export default CardItem